jQuery(document).ready(function () {
    jQuery(".wm-inventoryList__table").tablesorter({
        theme: 'blue',
        widthFixed: true,
        widgets: ["zebra", "filter"],
        ignoreCase: false,
        widgetOptions: {
            filter_columnFilters: true,
            filter_columnAnyMatch: true,
            filter_cellFilter: '',
            filter_cssFilter: '',
            filter_defaultFilter: {},
            filter_excludeFilter: {},
            filter_external: '',
            filter_filteredRow: 'filtered',
            filter_filterLabel: 'Filter "{{label}}" column by...',
            filter_formatter: null,
            filter_functions: null,
            filter_hideEmpty: true,
            filter_hideFilters: true,
            filter_ignoreCase: true,
            filter_liveSearch: true,
            filter_matchType: { 'input': 'exact', 'select': 'exact' },
            filter_onlyAvail: 'filter-onlyAvail',
            filter_placeholder: { search: '', select: '' },
            filter_reset: 'button.reset',
            filter_resetOnEsc: true,
            filter_searchDelay: 300,
            filter_searchFiltered: true,
            filter_selectSource: null,
            filter_serversideFiltering: false,
            filter_startsWith: false,
            filter_useParsedData: false,
            filter_defaultAttrib: 'data-value',
            filter_selectSourceSeparator: '|'
        }
    });
    jQuery(".wm-inventoryList__table").tablesorterPager({
        container: jQuery('.wm-inventoryList__pager'),
        size: 25,
        output: 'showing: {startRow} to {endRow} ({filteredRows})'
    });
    jQuery('.wm-inventoryList__table tbody tr').hover(function () {
        jQuery(this).addClass('-hover');
    }, function () {
        jQuery(this).removeClass('-hover');
    });
    jQuery('.wm-singleTransformer__contactInquiry a').click(function (e) {
        e.preventDefault();
        var title = jQuery('.wm-singleTransformer__title').html();
        jQuery('#wm-modalForm').modal();
        jQuery('#wm-modalForm').find('select[name=series]').empty().append('<option value="' + title + '">' + title + '</option>').val(title);
    });
});
