jQuery(document).on('ready', function () {
    setTimeout(function () {
        jQuery('#phantom').find('.wpml-ls-item-toggle').on('click', function () {
            const subMenu = jQuery(this).parent().siblings('.wpml-ls-sub-menu');
            if (subMenu.css('visibility') === 'visible') {
                subMenu.css('visibility', 'hidden');
            }
            else {
                subMenu.css('visibility', 'visible');
            }
        });
    }, 2000);
});
