jQuery(document).ready(function () {
    var $toggle = jQuery('.wm-transformerSection__toggle');
    var $toggleSections = $toggle.prev();
    $toggleSections.css('display', 'none');
    $toggle.click(function () {
        jQuery(this).toggleClass('-active').prev().slideToggle();
        if (jQuery(this).hasClass('-active')) {
            window.location.hash = jQuery(this).data('target');
        }
    });
    jQuery('.wm-transformerMenu__itemInner').click(function () {
        jQuery(jQuery(this).attr('data-target')).find('.wm-transformerSection__toggle').click();
    });
    jQuery('.wm-transformerSection .anchor-link').click(function () {
        let id = jQuery(this).attr('href').replace('#!/', '#');
        jQuery(id).find('.wm-transformerSection__toggle').click();
    });
    jQuery('.wm-table:not(.-enclosure) tbody tr').click(function () {
        jQuery('#wm-modalForm').find('select[name=series]').empty();
        jQuery(this).parent().find('tr').each(function () {
            var value = modal_form_select_value(this);
            var option = '<option value="' + value + '">' + value + '</option>';
            jQuery('#wm-modalForm').find('select[name=series]').append(option);
        });
        jQuery('#wm-modalForm').modal();
        var value = modal_form_select_value(this);
        jQuery('#wm-modalForm').find('select[name=series]').val(value);
    });
    function modal_form_select_value(tr) {
        var series = jQuery(tr).attr('data-series');
        var kva = jQuery(tr).find('td:first-child').text();
        return series + ' / ' + kva + 'KVA';
    }
});
